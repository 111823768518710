import React from 'react'

function ErrorMsg() {
    return (
        <div>
            Fehler: Bilder konnten nicht geladen werden.
        </div>
    )
}

export default ErrorMsg
