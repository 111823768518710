
import './App.css';
import React from "react";
import { gsap } from "gsap";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
//import 'semantic-ui-css/semantic.min.css'

function App(props) {
  const handle = useFullScreenHandle()
  const [imageList,setImageList] = React.useState([])
  const img1Ref = React.useRef()
  const img2Ref = React.useRef()
  const logoRef = React.useRef()
  const btnRef = React.useRef()
  const timeline = React.useRef()
  const bannerRef=React.useRef()
  const loadingRef=React.useRef()
  const [run, setRun] = React.useState(false)
  const dtime = 5
  
  //Handle start Fullscreen Mode
  function handleStart(){
    handle.enter()
  }

  //React to changes between Fullscreen Mode and non-Fullscreen mode
  const reportChange = React.useCallback((state, handle) =>{
    if(state){
      console.log("Going Fullscreen")
      gsap.set(btnRef.current,{display: 'none'})
      setRun(true)
    }else{
      console.log("Leaving Fullscreen")
      gsap.set(btnRef.current,{display: 'block'})
      setRun(false)
    }
  })

  //Fetch Image URLs from Omnicore Backend
  //TODO: Prefetch all images from the list and store them base64 encoded in an image-Array.
  

  React.useEffect(() => {
    setImageList(props.images)
    
    gsap.set(img1Ref.current,{opacity:0})
    gsap.set(img2Ref.current,{opacity:0})
    gsap.set(loadingRef.current,{display: "none"})


  },[]);

  React.useEffect(() => {
    timeline.current = gsap.timeline({repeat:10})
    if(run){
      console.log("Running Diashow")
      timeline.current.to(logoRef.current,{top: "5%", duration:2},0)
      timeline.current.to(bannerRef.current,{opacity: 1, duration: 3},3)
      let pos=1
      for(let x=0; x<imageList.length; x++){
        pos+=2
        timeline.current.set(img1Ref.current, { attr: { src: imageList[x] }},pos) 
        timeline.current.to(img1Ref.current,{opacity: 1, duration: 2},pos)
        pos +=(3+dtime)
        timeline.current.to(img1Ref.current,{opacity: 0, duration: 2},pos)
      }
    }else{
      timeline.current.pause(0) //BUG: Neither Pause, nor restart or Clear are working. Investigate
      gsap.set(bannerRef.current,{opacity: 0})
      console.log("Clearing timeline")
    }
  },[run,imageList]);
  
  return (
    <FullScreen handle={handle} onChange={reportChange} className="screen">
      <img src="logo_light_bg.png" alt="Logo" className="logo" ref={logoRef} />
      <button onClick={handleStart} ref={btnRef} className="btn">Präsentation starten</button>
      
      <img src={imageList[0]} ref={img1Ref} alt="img1"  className="initPic img1"></img>
      <img src={imageList[1]} ref={img2Ref} alt="img2"  className="initPic img2"></img>
      <center><div ref={bannerRef} className="banner"><center><h1><span className="infos">Weitere Infos unter:</span> http://grandedesigns.de</h1></center></div></center>
      <div className="loading" ref={loadingRef}>
        Lade Bilder:
      </div>
    </FullScreen>
  );
}

export default App;
