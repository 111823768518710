import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ErrorMsg from './Error';
import reportWebVitals from './reportWebVitals';

var imageList=[]

function getImageList(){
  console.log("fetching images")
  fetch("http://omnicore.grandedesigns.de/api/galleryimages/")
  .then((result) => { return result.json(); })
  .then((content) => { 
    let ret=[]
    for(let a=0;a<content.length;a++){
      ret.push(content[a])
    }
  imageList=ret
  RunApp(imageList)
  })
  .catch(function(){
    ReactDOM.render(
      <React.StrictMode>
        <ErrorMsg/>
      </React.StrictMode>,
      document.querySelector("#root")
    )
  });
}


function RunApp(images=[],err=null){
  
  ReactDOM.render(
    <React.StrictMode>
      <App images={images} err={err} />
    </React.StrictMode>,
    document.querySelector("#root")
  );
}

getImageList()


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
